import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator, enableIndexedDbPersistence } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAIphKgh86eLCsl-Y4xZ6XeJJ4opmW4ijI",
  authDomain: "onioncoffee-c5fb9.firebaseapp.com",
  projectId: "onioncoffee-c5fb9",
  storageBucket: "onioncoffee-c5fb9.appspot.com",
  messagingSenderId: "503205175895",
  appId: "1:503205175895:web:a058fff71cf50faaae3912",
  measurementId: "G-GK5V0SEZNR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const initializeEmulators = async () => {
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    try {
      console.log('Setting up Firebase emulators...');
      
      // Connect to Auth emulator
      connectAuthEmulator(auth, 'http://127.0.0.1:9098', { disableWarnings: true });
      console.log('Connected to Auth emulator');

      // Connect to Firestore emulator
      connectFirestoreEmulator(db, '127.0.0.1', 8081);
      console.log('Connected to Firestore emulator');

      // Connect to Storage emulator
      connectStorageEmulator(storage, '127.0.0.1', 9198);
      console.log('Connected to Storage emulator');

      // Enable offline persistence for Firestore
      try {
        await enableIndexedDbPersistence(db);
      } catch (err) {
        if (err.code === 'failed-precondition') {
          console.warn('Multiple tabs open, persistence can only be enabled in one tab at a a time.');
        } else if (err.code === 'unimplemented') {
          console.warn('The current browser does not support persistence.');
        }
      }

      console.log('Successfully connected to all emulators');
    } catch (error) {
      console.error('Error connecting to emulators:', error);
    }
  }
};

// Initialize emulators
initializeEmulators();

export { auth, db, storage };