import React, { useState } from 'react';
import { db } from '../firebase/config';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { useAuth, TEST_USER } from '../contexts/AuthContext';
import '../styles/Login.css';

const TEST_MODE = false;  // Set to false to allow real logins

const TEST_USER_DATA = {
  firstName: 'Test User',
  phoneNumber: TEST_USER.phoneNumber,
  age: '25',
  setupComplete: true,
  isAdmin: false,
  isTestUser: true,
  createdAt: new Date().toISOString(),
  lastUpdated: new Date().toISOString(),
  groupIds: [],
  coffeeAdded: []
};

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsTestUser } = useAuth();

  const handleTestLogin = async () => {
    setLoading(true);
    setError('');
    try {
      const testUserRef = doc(db, 'users', TEST_USER.phoneNumber);
      await setDoc(testUserRef, TEST_USER_DATA, { merge: true });
      setIsTestUser(true);
      navigate('/dashboard');
    } catch (error) {
      console.error('Test login error:', error);
      setError('Failed to login. Please check your connection and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (TEST_MODE && phoneNumber === TEST_USER.phoneNumber) {
        setStep(2);
      } else if (!TEST_MODE) {
        // Add logic for handling real user verification here
        // For instance, sending a verification code to the provided phone number
        console.log('Proceeding with real user verification');
        setStep(2);
      } else {
        throw new Error('Please use the test account number shown above.');
      }
    } catch (error) {
      console.error('Verification error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (TEST_MODE && phoneNumber === TEST_USER.phoneNumber && 
          verificationCode === TEST_USER.verificationCode) {
        await handleTestLogin();
      } else if (!TEST_MODE) {
        // Implement actual verification code submission for real users
        console.log('Verifying real user code');
        navigate('/dashboard');
      } else {
        throw new Error('Invalid verification code');
      }
    } catch (error) {
      console.error('Code verification error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 space-y-6">
        <h2 className="text-2xl font-bold text-center text-gray-900">onion.coffee</h2>
        
        {TEST_MODE && (
          <div className="bg-yellow-50 p-4 rounded-md">
            <p className="text-sm font-medium text-yellow-800">Test Mode Active</p>
            <p className="text-sm text-yellow-700">Use these credentials:</p>
            <p className="text-sm text-yellow-700">Phone: {TEST_USER.phoneNumber}</p>
            {step === 2 && <p className="text-sm text-yellow-700">Code: {TEST_USER.verificationCode}</p>}
          </div>
        )}

        {error && <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">{error}</div>}
        
        <form onSubmit={step === 1 ? handleVerificationSubmit : handleCodeSubmit} className="space-y-4">
          {step === 1 ? (
            <div className="space-y-4">
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ''))}
                placeholder="5551234567"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                disabled={loading}
                required
              />
              <button 
                type="submit"
                className="w-full px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-blue-300"
                disabled={loading || !phoneNumber}
              >
                {loading ? 'Processing...' : 'Continue'}
              </button>
            </div>
          ) : (
            <div className="space-y-4">
              <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value.replace(/\D/g, ''))}
                placeholder="Enter verification code"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                disabled={loading}
                required
                maxLength={6}
              />
              <button 
                type="submit"
                className="w-full px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-blue-300"
                disabled={loading || verificationCode.length !== 6}
              >
                {loading ? 'Verifying...' : 'Verify Code'}
              </button>
              <button 
                type="button"
                onClick={() => {
                  setStep(1);
                  setVerificationCode('');
                  setError('');
                }}
                className="w-full px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400"
                disabled={loading}
              >
                Back
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
