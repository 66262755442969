import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db, storage } from '../firebase/config';
import { 
  onAuthStateChanged, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut 
} from 'firebase/auth';
import { 
  doc, 
  getDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  updateDoc, 
  addDoc, 
  serverTimestamp, 
  limit,
  setDoc 
} from 'firebase/firestore';
import { 
  ref, 
  uploadBytes, 
  getDownloadURL 
} from 'firebase/storage';

// Test user configuration
export const TEST_USER = {
  uid: 'test-user-5551234567',
  phoneNumber: '5551234567',
  verificationCode: '123456'
};

const TEST_USER_DATA = {
  firstName: 'Test User',
  phoneNumber: TEST_USER.phoneNumber,
  age: '25',
  setupComplete: true,
  isAdmin: false,
  isTestUser: true,
  createdAt: new Date().toISOString(),
  lastUpdated: new Date().toISOString(),
  groupIds: [],
  coffeeAdded: []
};

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [setupComplete, setSetupComplete] = useState(false);
  const [isTestUser, setIsTestUser] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleUserData = async (userId, isTest = false) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log('AuthContext - User data loaded:', userData);
        setSetupComplete(userData.setupComplete || false);
        setIsAdmin(userData.isAdmin || false);
        return userData;
      } else if (isTest) {
        // Create test user if it doesn't exist
        await setDoc(userRef, TEST_USER_DATA);
        setSetupComplete(true);
        return TEST_USER_DATA;
      }
      return null;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };

  useEffect(() => {
    console.log('Setting up auth listener...');
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('Auth state changed:', { user, isTestUser });

      try {
        if (user || isTestUser) {
          const userId = isTestUser ? TEST_USER.phoneNumber : user?.uid;
          const userData = await handleUserData(userId, isTestUser);
          
          if (userData) {
            setCurrentUser(isTestUser ? { ...TEST_USER, ...userData } : user);
          } else {
            setCurrentUser(isTestUser ? TEST_USER : user);
            setSetupComplete(false);
            setIsAdmin(false);
          }
        } else {
          setCurrentUser(null);
          setSetupComplete(false);
          setIsTestUser(false);
          setIsAdmin(false);
          console.log('No user authenticated');
        }
      } catch (error) {
        console.error('Error in auth state change:', error);
        setCurrentUser(null);
        setSetupComplete(false);
        setIsTestUser(false);
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [isTestUser]);

  useEffect(() => {
    console.log('AuthContext Debug:', {
      currentUser,
      setupComplete,
      isTestUser,
      isAdmin,
      loading,
    });
  }, [currentUser, setupComplete, isTestUser, isAdmin, loading]);

  const logout = async () => {
    try {
      await signOut(auth);
      setIsTestUser(false);
      return true;
    } catch (error) {
      console.error('Logout error:', error);
      return false;
    }
  };

  const value = {
    currentUser,
    login: signInWithEmailAndPassword,
    signup: createUserWithEmailAndPassword,
    logout,
    setupComplete,
    isTestUser,
    isAdmin,
    setIsTestUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
export default AuthProvider;